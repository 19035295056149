// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

// rails-ujsを一度だけ開始
if (typeof Rails !== 'undefined' && Rails.start) {
  Rails.start()
}
Turbolinks.start()

// require("@rails/activestorage").start();
// require("channels");

require("jquery");
require("@nathanvda/cocoon");
require("swiper/swiper-bundle");
require("swiper");

require("jquery-jpostal-ja");

// require("src/js/application")
import "lazysizes";
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
  "--medium": "(max-width: 768px)",
};
window.lazySizesConfig.loadMode = 1;
lazySizes.init();

import "src/js/application.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "flowbite";
import { initFlowbite } from "flowbite";
window.document.addEventListener("turbolinks:load", (event) => {
  initFlowbite();
});

//
import "../stylesheets/style.scss";

//
// import "material-icons/iconfont/outlined.scss";
// import "material-icons/iconfont/round.scss";
import "material-symbols/outlined.css";
import "material-symbols/rounded.css";
import "src/js/booking/confirmSubmit.js";
import "src/js/rental_space/detail.js";
import "src/js/rental_space/review.js";
import "src/js/shared/likeActions";
import "src/js/shared/spaceReservationAvailablity";
import "src/js/shared/switchTab";
import "src/js/shared/searchForm";

// toast
import { showFailureToast, showSuccessToast } from "src/tsx/Toast";
window.showSuccessToast = showSuccessToast;
window.showFailureToast = showFailureToast;

// app/assets/javascripts/stripe_setup.js

document.addEventListener("DOMContentLoaded", () => {
  if (!document.querySelector("#express-checkout-element")) {
    return;
  }

  const stripe = Stripe(
    "pk_test_51NKiLlFjGHuuXSaAjabZNRTTM561fqyP7pCBp0NRVcZnfNrSu7dGxkSFdChluhJVwRx9eTJP63mIS0MMbDJxbM6B00dL0ypqQg"
  );
  const options = {
    mode: "payment",
    paymentMethodCreation: "manual",
    amount: parseInt(
      document
        .querySelector("[data-stripe-express-checkout-amount]")
        .getAttribute("data-stripe-express-checkout-amount")
    ),
    currency: "jpy",
    // Customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  // Set up Stripe.js and Elements to use in checkout form
  const elements = stripe.elements(options);

  const expressCheckoutElement = elements.create("expressCheckout", {
    wallets: {
      googlePay: "always",
      applePay: "always",
    },
    buttonType: {
      googlePay: "book",
      applePay: "book",
    },
    buttonTheme: {
      googlePay: "black",
      applePay: "black",
    },
    layout: {
      maxRows: 1,
    },
  });
  expressCheckoutElement.mount("#express-checkout-element");

  const handleError = (error) => {
    const messageContainer = document.querySelector("#error-message");
    messageContainer.textContent = error.message;
  };

  expressCheckoutElement.on("confirm", async (event) => {
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create a PaymentMethod using the details collected by the Express Checkout Element
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        // billing_details: {
        //   name: "Jenny Rosen",
        // },
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
      return;
    }

    // #js-reserve-booking-form の要素内に hidden_field として payment_method_id を追加する
    const paymentMethodId = document.createElement("input");
    paymentMethodId.setAttribute("type", "hidden");
    paymentMethodId.setAttribute("name", "payment_method_id");
    paymentMethodId.setAttribute("value", paymentMethod.id);
    document
      .querySelector("#js-reserve-booking-form")
      .appendChild(paymentMethodId);

    $("#js-reserve-booking-form").submit();
  });
});

// aタグのhref属性にtel:が含まれている場合、
// PCでクリックしたときに電話番号を表示する
document.addEventListener("turbolinks:load", () => {
  var ua = navigator.userAgent.toLowerCase();
  var isMobile = /iphone/.test(ua) || /android(.+)?mobile/.test(ua);
  var telLinks = document.querySelectorAll('a[href^="tel:"]');
  if (!isMobile && !!telLinks) {
    telLinks.forEach(function (link) {
      link.addEventListener("click", function (e) {
        e.preventDefault();
        var phoneNumber = link.getAttribute("href").replace("tel:", "");
        alert(`電話番号：${phoneNumber}`);
      });
    });
  }
});

document.addEventListener("turbolinks:load", function () {
  var readMoreButtons = document.querySelectorAll(".js-read-more");

  readMoreButtons.forEach(function (button) {
    // テキスト要素の高さをチェック
    const textElement = button.previousElementSibling;
    const isTextClamped = textElement.scrollHeight > textElement.clientHeight;

    // テキストが省略されていない場合はボタンを非表示
    if (!isTextClamped) {
      button.style.display = "none";
    }

    button.addEventListener("click", function () {
      textElement.classList.remove("line-clamp-3");
      this.style.display = "none";
    });
  });
});

// ブラウザバックしたときに .js-space-booking-loading が表示されたままになるのを防ぐ
window.addEventListener("pageshow", function (event) {
  var historyTraversal =
    event.persisted ||
    (typeof window.performance != "undefined" &&
      window.performance.navigation.type === 2);
  if (historyTraversal) {
    var spaceBookingLoading = document.querySelector(
      ".js-space-booking-loading"
    );
    if (
      spaceBookingLoading &&
      !spaceBookingLoading.classList.contains("hidden")
    ) {
      spaceBookingLoading.classList.add("hidden");
    }
  }
});


document.addEventListener('turbolinks:load', () => {
  const header = document.getElementById('header');
  const hero = document.getElementById('home-hero');
  const stickySearchButton = document.getElementById('home-sp-sticky-search-button');

  if (!header || !hero) return;

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          header.classList.add('hidden');
          stickySearchButton.classList.add('hidden');
        } else {
          header.classList.remove('hidden');
          stickySearchButton.classList.remove('hidden');
        }
      });
    },
    { threshold: 0 }
  );

  observer.observe(hero);
});
